import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService: AuthService = inject(AuthService);

  let newReq: HttpRequest<unknown> = req.clone();

  // Request
  //
  // Se o access token não estiver expirado, adicionaremos o header de autorização.
  // Não adicionaremos esse header para um token expirado.
  // Dessa forma forçamos o servidor a responder com um 401 mais rápido
  // e o interceptor se encarregará de deslogar o usuário
  if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
    newReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken)
    });
  }

  // Response
  return next(newReq).pipe(
    catchError((error) => {
      // Se o erro for um erro de autorização
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Removemos as credenciais do usuário.
        // TODO: Adicionar lógica do refresh token.
        authService.signOut();
        location.reload();
      }

      return throwError(() => error);
    })
  );
};
