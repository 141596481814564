import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideClientHydration, withI18nSupport } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAuth } from '@core/auth/auth.provider';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(withI18nSupport()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideEnvironmentNgxMask(),
    provideAnimationsAsync(),
    provideAuth(),
    provideLottieOptions({
      player: () => player
    })
    // Ainda não está sendo suportado
    // SSR COM Zoneless, porém bora usar apenas Signals.
    // provideExperimentalZonelessChangeDetection()
  ]
};
