import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, UrlTree } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const router: Router = inject(Router);

  return inject(AuthService)
    .check()
    .pipe(
      switchMap((authenticated: boolean) => {
        // Se o usuário não estiver autenticado
        if (!authenticated) {
          // Ele será redirecionado para a página de sign-in, com o parâmetro para ser redirecionado para a última página visitada
          const redirectURL: string = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
          const urlTree: UrlTree = router.parseUrl(`sign-in?${redirectURL}`);

          return of(urlTree);
        }

        // TODO: Adicionar validação se o usuário pode ter acesso a essa rota

        // Acesso permitido
        return of(true);
      })
    );
};
