import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import updateLocale from 'dayjs/plugin/updateLocale';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { provideLottieOptions } from 'ngx-lottie';
dayjs.extend(updateLocale);

@Component({
  selector: 'agj-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web')
    })
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  public ngOnInit(): void {
    const firebaseConfig = {
      apiKey: 'AIzaSyBK2IB_ymbv1DogQwNvvMetLTsSdrOwDLY',
      authDomain: 'agendaja-4679f.firebaseapp.com',
      projectId: 'agendaja-4679f',
      storageBucket: 'agendaja-4679f.appspot.com',
      messagingSenderId: '207313541082',
      appId: '1:207313541082:web:5f1e2db528fdb23d7d92e3',
      measurementId: 'G-8NBETW5LRC'
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // Não inicializar em ambientes não suportados
    isSupported().then((isSupported: boolean) => isSupported && getAnalytics(app));

    this.updateDayJsLocale();
  }

  // TODO: Quando incluirmos o módulo de tradução, levar isso para lá
  private updateDayJsLocale(): void {
    dayjs.locale('pt-br', ptBR);
  }
}
