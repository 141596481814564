import { Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noAuth.guard';

export const routes: Routes = [
  // Essa url não existirá, é apenas uma forma simples de mudar a primeira url aberta após o login
  // sem ter que mexer nos componentes relacionados
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'agendamentos' },

  // Rotas não autenticadas que só podem ser visitadas por usuário NÃO AUTENTICADOS
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    children: [
      { path: 'sign-in', loadChildren: () => import('./pages/auth/sign-in/sign-in.routes') },
      { path: 'sign-up', loadChildren: () => import('./pages/auth/sign-up/sign-up.routes') },
      { path: 'confirm-sign-in', loadChildren: () => import('./pages/auth/confirm-sign-in/confirm-sign-in.routes') }
    ]
  },

  // Rotas autenticadas
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadComponent: () => import('./pages/home/home.component').then((c) => c.HomeComponent),
    children: [
      {
        path: 'agendamentos',
        loadComponent: () => import('./pages/my-bookings/my-bookings.component').then((c) => c.MyBookingsComponent)
      },
      {
        path: 'meu-perfil',
        loadComponent: () => import('./pages/my-profile/my-profile.component').then((c) => c.MyProfileComponent)
      },
      {
        path: 'agendamento/:id',
        loadComponent: () => import('./pages/my-booking/my-booking.component').then((c) => c.MyBookingComponent)
      },
      {
        path: 'minhas-avaliacoes',
        loadComponent: () => import('./pages/my-profile/my-ratings/my-ratings.component').then((c) => c.MyRatingsComponent)
      },
      {
        path: 'sobre-agendaja',
        loadComponent: () => import('./pages/about-agendaja/about-agendaja.component').then((c) => c.AboutAgendajaComponent)
      },
      {
        path: 'editar-perfil',
        loadComponent: () => import('./pages/my-profile/edit-profile/edit-profile.component').then((c) => c.EditProfileComponent)
      },
      {
        path: ':CompanySlug/agendar/confirmacao',
        loadComponent: () =>
          import('./pages/booking/components/booking-confirm-step/booking-confirm-step.component').then((c) => c.BookingConfirmStepComponent)
      }
    ]
  },

  // Rotas não autenticadas que podem ter acesso de qualquer usuário.
  {
    path: '',
    children: [
      { path: 'offline', loadComponent: () => import('./pages/offline/offline.component').then((c) => c.OfflineComponent) },
      {
        path: 'agenda-ja/404',
        loadComponent: () => import('./pages/not-found/not-found.component').then((c) => c.NotFoundComponent)
      },
      {
        path: 'test/button',
        loadComponent: () => import('./shared/components/agj-button/agj-button-test.component').then((c) => c.AgjButtonTestComponent)
      },
      {
        path: ':CompanySlug',
        loadComponent: () => import('./core/components/company-core-page/company-core-page.component').then((c) => c.CompanyCorePageComponent),
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/company/company.component').then((c) => c.CompanyComponent)
          },
          {
            path: 'pesquisar',
            loadComponent: () => import('./pages/search/search.component').then((c) => c.SearchComponent)
          },
          {
            path: 'agendar',
            children: [
              { path: '', loadComponent: () => import('./pages/booking/booking.component').then((c) => c.BookingComponent) },
              {
                path: 'revisao',
                loadComponent: () =>
                  import('./pages/booking/components/booking-summary/booking-summary.component').then((c) => c.BookingSummaryComponent)
              }
            ]
          }
        ]
      }
    ]
  }
];
